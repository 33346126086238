/* ===========================================================================
© Mesomorphic Ltd
============================================================================= */
import $ from 'jquery';

$(document).on('change', '#available_customer_order_extra_item_type', event => {
  const downloadCheck = $('#available_customer_order_extra_download_only');

  const isPattern = event.target.value === 'pattern';
  downloadCheck.prop('disabled', !isPattern);
  downloadCheck.closest('label').toggleClass('disabled', !isPattern);

  if (!isPattern) {
    if (downloadCheck.prop('checked') === true) {
      downloadCheck.prop('checked', false);
    }
  }
});

/* ===========================================================================
© Mesomorphic Ltd
============================================================================= */
import $ from 'jquery';
import setupSelects from './helpers/setup_selects';

// Constants for common CSS selectors used (so you don't have to hunt them down).
const YARN = {
  card: '#order-body .card',
  card_remove: '.remove_button',
  card_header_toggle: '#order-body .card-header-toggle',
  card_items: '.order_items',
  card_plymakeup: '.makeup_id',
  item_form: '.order_item_form',
  item_ply_colour: '.ply_colour_id',
  item_remove: '.remove_order_item',
  item_id: '.hidden_makeup_id',
  item_shipped: '#order-body .shipped_date',
};

const EXTRAS = {
  body: '#order-extra-body',
  rows: '#order-extra-body .row:visible',
  new: '#order-extra-body .row[isnew]',
  remove: '.remove_extra_button',
  shipped: '.shipped_date',
  inputs: '#order-extra-body select, #order-extra-body input',
};

let makeups;
let submitting = false;

function getSelectorAsClassName(selector) {
  // return a css selector as a class name
  return selector.replace('.', '');
}

function itemHasInputs(item) {
  // return true / false if any inputs exist for item
  const plyColourSelect = $(item).find(YARN.item_ply_colour);
  const quantityControl = $(item).find('.number');
  const noteInput = $(item).find('#row_note');
  const millNoteInput = $(item).find('#mill_note');
  const result = []; // Using an array so it can be inspected when needed

  if (plyColourSelect.prop('selectedIndex') > 0) result.push(plyColourSelect.find(':selected').text());
  if (quantityControl.val()) result.push(quantityControl.val());
  if (noteInput.val()) result.push(noteInput.val());
  if (millNoteInput.val()) result.push(millNoteInput.val());

  return result.length > 0;
}

function cardHasInputs(card) {
  // return true / false if any inputs exist for card and any of its items
  const plyMakeupSelect = $(card).find(YARN.card_plymakeup);
  const items = card.querySelectorAll(YARN.item_form);
  const result = []; // Using an array so it can be inspected when needed

  // check for ply makeup selection
  if (plyMakeupSelect.prop('selectedIndex') > 0) result.push(plyMakeupSelect.find(':selected').text());
  // check each item for inputs
  items.forEach(item => {
    if (itemHasInputs(item)) result.push(item);
  });
  return result.length > 0;
}

function getLastCardItem(card) {
  // return last item in card
  return card.find(YARN.item_form).last();
}

function getCardItemsTotal(card) {
  // return total number of card items for parameter or all items from all cards.
  const searchElement = card ? $(card) : $(YARN.card_items);
  return searchElement.find(YARN.item_ply_colour).length;
}

function getCardsTotal() {
  // return total number of cards
  return $(YARN.card).length;
}

function setPlyColourSelects(selects, id) {
  // populate a ply colour select for the specified ply/makeup id
  selects.children('option').remove();

  if (id) {
    $.ajax(`/makeup_plies/${id}`).done(response => {
      selects.append($('<option />')
        .val(null)
        .text('Select Colour'));

      response.forEach(plyColour => {
        selects.append($('<option />')
          .val(plyColour.id)
          .text(plyColour.display_name));
      });
    });
  }
}

function getParentCard(childElement) {
  // return the parent card element for the child element parameter
  return childElement.parents(YARN.card);
}

function expandParentCard(childElement) {
  // Ensure the card containing the element parameter is expanded
  const toggle = getParentCard(childElement).find(YARN.card_header_toggle);

  if (toggle[0] && toggle[0].classList.contains('collapsed')) {
    toggle.find('button.expand').trigger('click');
  }
}

function scrollToElement(element) {
  element[0].scrollIntoView();
}

function getNewItemId(parent, selector = '*') {
  let result = 1;

  const ids = Array.from(parent.querySelectorAll(`${selector}`)).map(item => parseInt(item.id.match(/\d+/), 10));
  while (ids.includes(result)) { result += 1; }
  return result;
}

// Return a unique card element ID (int)
function getNewCardId() {
  // return new ID for a card that is not in use already
  const ids = [];
  let result = 1;
  // Iterate through existing elements and extract numeric value from their id
  document.querySelectorAll(YARN.card_header_toggle).forEach(item => ids.push(parseInt(item.id.match(/\d+/)[0], 10)));
  // Iterate through numeric ids until a unique one is found
  while (ids.includes(result)) { result += 1; }

  return result;
}

function getCardMakeupId(card) {
  // return the ply/makeup id from the given card parameter
  return makeups[card.find(YARN.card_plymakeup).children('option:selected').text().replace(/^(.*?)(?=\b(?:Balls|Clean|Oil)\b)/, '')];
}

function getMakeups() {
  // Fetch makeups if not yet loaded
  if (!makeups) {
    $.getJSON('/makeups/', response => {
      makeups = {};
      $.each(response, (index, makeup) => {
        makeups[makeup.name] = makeup.id;
      });
    });
  }
}

function updateControlStatuses() {
  // updates assorted controls to reflect the current state.
  // special care must be made to not disable removal buttons
  // when the form is being submitted.

  // disable removing card when it's the last remaining card
  $(YARN.card_remove).attr('disabled', getCardsTotal() === 1);

  // disable remove button on empty card items, and keep disabled items that are now unavailable
  document.querySelectorAll(YARN.item_form).forEach(item => {
    item.querySelectorAll(YARN.item_remove).forEach(button => {
      $(button).attr('disabled', ((!submitting && !itemHasInputs(item))) || $(button).hasClass('keep_disabled'));
    });
  });
}

function irrelevantExtras() {
  return $(EXTRAS.new).filter((ri, row) => !Array.from(row.querySelectorAll('input, select')).find(input => input.value !== ''));
}

function relevantExtras() {
  return $(EXTRAS.rows).filter((ri, row) => Array.from(row.querySelectorAll('input, select')).find(input => input.value !== ''));
}

function relevantDateSelects() {
  return Array.from(document.querySelectorAll(YARN.item_shipped)).concat(
    Array.from(relevantExtras().find(EXTRAS.shipped)),
  );
}

/* eslint-disable no-use-before-define */
function ensureOrderExtraHasNewItem() {
  if (irrelevantExtras().length === 0) {
    addOrderExtra();
  }
}

function setupOrderExtraEvents(addedExtraRow) {
  addedExtraRow.querySelector(EXTRAS.remove).onclick = removeOrderExtra;
  addedExtraRow.querySelectorAll('input, select').forEach(control => {
    control.onchange = () => { ensureOrderExtraHasNewItem(); };
  });
}

function addOrderExtra() {
  // add a new row for specifying an extra
  const orderExtras = document.querySelector(EXTRAS.body);
  const newRowId = getNewItemId(orderExtras);

  // eslint-disable-next-line no-undef
  const newRow = orderExtraRow.replaceAll(/\[\]/g, `[${newRowId}]`).replaceAll(/__/g, `_${newRowId}_`);
  $(orderExtras).append(newRow).hide().fadeIn();

  setupOrderExtraEvents(orderExtras.lastElementChild);
  setupSelects();
}

function removeOrderExtra(event) {
  const targetRow = event.target.parentNode.parentNode;
  // always remote the selected target row even if it is the only one.
  // this ensures the user can always delete an already posted extra
  // or remove an unposted extra where they have already made some edits
  $(targetRow).fadeOut(() => {
    if (targetRow.getAttribute('isnew')) {
      targetRow.remove();
    } else {
      const name = $(targetRow).find('input').attr('name').replace('[quantity]', '[_destroy]');
      $(targetRow).append(`<input type="hidden" value="1" name="${name}" />`);
    }

    // if we now have no extra rows add a blank one back in
    if ($(EXTRAS.rows).length === 0) addOrderExtra();
  });
}
/* eslint-enable no-use-before-define */

$(document).on('change', EXTRAS.inputs, () => {
  ensureOrderExtraHasNewItem();
});

function addCard() {
  // adds a new card with an empty item
  const orderItems = $('#order-body');
  const newRowId = getNewItemId(document, YARN.item_id);

  // eslint-disable-next-line no-undef
  let newRow = plyMakeupCard.replaceAll(/\[\d\]/g, `[${newRowId}]`).replaceAll(/_\d_/g, `_${newRowId}_`);
  // Ensure unique card id for expand collapse functionality
  newRow = newRow.replaceAll(`card_${newRowId}_section`, getNewCardId());
  orderItems.append(newRow);

  setupSelects();
  updateControlStatuses();

  // Ensure ply makeup selector is visible and focused
  const plyMakeup = orderItems.find(YARN.card).last().find(YARN.card_plymakeup);
  scrollToElement(getParentCard(plyMakeup));
  setTimeout(() => {
    plyMakeup.focus();
  }, 300);
}

function addCardItem(card) {
  // adds a new item to the given card parameter
  const orderItems = card.find(YARN.card_items);
  const newRowId = getNewItemId(document, YARN.item_id);

  // eslint-disable-next-line no-undef
  const newRow = orderItemRow.replaceAll(/\[\d\]/g, `[${newRowId}]`).replaceAll(/_\d_/g, `_${newRowId}_`);
  orderItems.append(newRow).find('.order_item_form:last-child').hide().fadeIn();

  const lastRow = orderItems.find(YARN.item_form).last();
  // linter happiness to be < 100 chars for setPlyColourSelects call
  const plyColourSelect = lastRow.find(YARN.item_ply_colour);
  setPlyColourSelects(plyColourSelect, card.find(YARN.card_plymakeup).val());

  const hiddenField = lastRow.find(YARN.item_id);
  hiddenField.val(getCardMakeupId(card));

  setupSelects();
  updateControlStatuses();

  return lastRow;
}

function ensureCardHasNewItem(card) {
  // Only add new item to a card when there isn't a new empty item already in place.
  const lastItem = getLastCardItem(card);

  return !lastItem[0] || itemHasInputs(lastItem) ? addCardItem(card) : lastItem;
}

function removeItem(itemForm) {
  // removes the given item parameter from it's card

  // Only remove unsaved items
  if (itemForm[0].getAttribute('isnew') === 'true') {
    // Not saved so remove from DOM
    itemForm.remove();
    return;
  }

  const name = itemForm.find('textarea').attr('name').replace('[note]', '[_destroy]');
  itemForm.append(`<input type="hidden" value="1" name="${name}" />`);

  // remove idetifying classes
  itemForm.removeClass(getSelectorAsClassName(YARN.item_form));
  const ITEM_CLASSES = [
    YARN.card_plymakeup,
    YARN.item_ply_colour,
  ];
  ITEM_CLASSES.forEach(className => {
    // index is required to get the element in the following each()
    itemForm.find(className).each((index, element) => {
      $(element).removeClass(getSelectorAsClassName(className));
    });
  });

  itemForm.fadeOut(0);
}

$(document).on('turbolinks:load', () => {
  updateControlStatuses();
  getMakeups();
});

$(document).on('click', '#submit-button', event => {
  submitting = true;

  // ensure remove things like remove buttons for empty items are enabled
  updateControlStatuses();

  // Remove empty cards
  document.querySelectorAll(YARN.card).forEach(card => {
    // Only remove empty cards when there's more than 1 card
    if (getCardsTotal() > 1 && !cardHasInputs(card)) {
      const plyMakeupRemoveButton = $(card).find(YARN.card_remove);

      plyMakeupRemoveButton
        .trigger('click', true)
        .promise()
        .done();
    }
  });

  // Remove empty card items
  document.querySelectorAll(YARN.item_form).forEach(item => {
    const card = item.closest(YARN.card);
    if (getCardItemsTotal(card) > 1 && !itemHasInputs(item)) {
      const removeButton = $(item).find(YARN.item_remove);
      removeButton
        .trigger('click', true)
        .promise()
        .done();
    }
  });

  // Remove empty customer order extras
  irrelevantExtras().each((index, extra) => { $(extra).remove(); });

  // prevent default form submission if there are no items
  if (getCardItemsTotal() === 0) {
    event.preventDefault();
  }

  submitting = false;
});

$(document).on('click', '.add_ply_makeup', addCard);
$(document).on('click', '.add_order_extra', addOrderExtra);

$(document).on('click', '.add_button', event => {
  // Adds a new item if there's not an empty item already
  const newItem = ensureCardHasNewItem($(event.target).parent().parent());
  expandParentCard(newItem);

  setTimeout(() => {
    scrollToElement(getLastCardItem(getParentCard(newItem)));
    newItem.find(YARN.item_ply_colour).focus();
  }, 500);
});

$(document).on('click', YARN.card_remove, function removeCard() {
  const card = $(this).closest(YARN.card);
  const cardID = card.find(YARN.item_ply_colour).val();
  const cardMakeup = card.find(YARN.item_ply_colour).find('option:selected').text();
  const cardItems = card.find(YARN.item_form);

  // Prompt to confirm card removal when:
  //  Not submitting the order
  //  A Ply / makeup has been selected
  // eslint-disable-next-line no-alert
  if (!submitting && cardID && !window.confirm(`Remove Ply/Makeup '${cardMakeup}' and all associated colours?`)) {
    return false;
  }

  // remove identifying class
  card.removeClass(getSelectorAsClassName(YARN.card));
  // remove all card items
  cardItems.each(function removeCardItem() {
    removeItem($(this));
  });

  card.fadeOut(400);

  // When card has no children (no previously saved items), remove it from DOM
  if (card.find(`[isnew=false]`).length === 0) {
    card.remove();
  } else {
    // Card contains hidden (pre-saved but now removed) items so:
    // clear ply makeup selection.
    $(card).find(YARN.card_plymakeup).val('');
    // mark as not required.
    $(card).find(YARN.card_plymakeup).attr('required', false);
  }

  updateControlStatuses();

  return true; // linter happiness
});

$(document).on('click', YARN.item_remove, event => {
  const item = $(event.target).closest(YARN.item_form);
  const itemCard = getParentCard(item.parent());
  const itemID = item.find('.select2').val() || item.find(':disabled').val();
  const itemColour = item.find('.select2').find('option:selected').text() || item.find('disabled').text();
  const itemMakeup = itemCard.find(YARN.card_plymakeup).find('option:selected').text();

  // Prompt to confirm ply colour item removal when:
  //  Not submitting the order
  //  A Ply colour has been selected
  // eslint-disable-next-line no-alert
  if (!submitting && itemID && !window.confirm(`Remove Colour '${itemColour}' from Ply/Makeup '${itemMakeup}'?`)) {
    return false;
  }
  removeItem(item);

  if (!submitting) ensureCardHasNewItem(itemCard);
  if (!submitting) updateControlStatuses();

  return true; // linter happiness
});

$(document).on('click', '.remove_extra_button', removeOrderExtra);
$(document).on('change', '.remove_extra_button', removeOrderExtra);

$(document).on('change', YARN.card_plymakeup, event => {
  const id = $(event.target).val();

  const card = $(event.target).parents(YARN.card);
  const plyColourSelects = card.find(YARN.item_ply_colour);
  setPlyColourSelects(plyColourSelects, id);

  const hiddenField = card.find(YARN.item_id);
  hiddenField.val(getCardMakeupId(card));

  if (id) {
    ensureCardHasNewItem(card);
  }

  expandParentCard(plyColourSelects);

  setTimeout(() => {
    plyColourSelects[0].focus();
  }, 100);
});

$(document).on('change', YARN.item_ply_colour, function handlePlyColourChanged(event) {
  const id = $(event.target).val();
  const quantity = $(event.target).parent().parent().find('input.number');

  if (id) {
    // An item was selected
    ensureCardHasNewItem($(this).parents(YARN.card));
    setTimeout(() => {
      quantity[0].focus();
    }, 100);
  }
});

// auto add new item when quantity is changed
$(document).on('change', '.number', function handleQuantityChanged() {
  ensureCardHasNewItem($(this).parents(YARN.card));
});

// When select2 combo dropped down, focus the search field
$(document).on('select2:open', event => {
  document.querySelector(`.select2-search__field[aria-controls='select2-${event.target.id}-results']`).focus();
});

// when shipped date for indvidual items changes, update the overall status
$(document).on('change', '.shipped_date', () => {
  const orderStatusSelect = document.getElementById('customer_order_order_status');
  // const itemDateSelects = Array.from(document.querySelectorAll(YARN.item_shipped));
  // const extraDateSelects = Array.from(relevantExtras().find(EXTRAS.shipped));
  const dateSelects = relevantDateSelects();
  const totalShipped = dateSelects.filter(control => control.value !== '').length;

  if (totalShipped > 0) {
    if (dateSelects.length > totalShipped) {
      orderStatusSelect.value = 'partially_shipped';
    } else {
      orderStatusSelect.value = 'shipped';
    }
  } else if (orderStatusSelect.value === 'shipped' || orderStatusSelect.value === 'partially_shipped') {
    orderStatusSelect.value = 'processing';
  }
});

// when the overall status is set to shipped set any empty shipped dates to today
$(document).on('change', '#customer_order_order_status', event => {
  if (event.target.value === 'shipped') {
    relevantDateSelects().filter(select => select.value === '').forEach(select => {
      const today = new Date();
      const day = `0${today.getDate()}`.slice(-2);
      const month = `0${today.getMonth() + 1}`.slice(-2);
      const year = today.getFullYear();
      select.value = `${year}-${month}-${day}`;
    });
  }

  // sync new value to other order status selects
  const controls = document.querySelectorAll('#customer_order_order_status');
  controls.forEach(control => {
    if (control !== event.target) control.value = event.target.value;
  });
});

$(document).on('change', '#customer_order_archived', event => {
  // sync new value to other order status selects
  const controls = document.querySelectorAll('#customer_order_archived');
  controls.forEach(control => {
    if (control !== event.target) control.checked = event.target.checked;
  });
});

function initialize() {
  const shadeCardOnlyCheckbox = document.getElementById('shade_card_only');
  const cardContainer = document.querySelector('#order-body');

  function toggleCardInputs(disabled) {
    const addPlyMakeupButton = document.getElementById('addPlyMakeupButton');
    if (addPlyMakeupButton) {
      addPlyMakeupButton.disabled = disabled;
    }

    cardContainer.querySelectorAll('.card:not(.unavailable)').forEach(card => {
      // ply/makeup selector, and add button
      card.querySelectorAll('.makeup_id, .add_button').forEach(input => {
        input.disabled = disabled;
      });

      // add and remove _destroy fields for any existing inputs
      card.querySelectorAll('.order_item_form').forEach(itemForm => {
        const name = $(itemForm).find('textarea').attr('name').replace('[note]', '[_destroy]');
        if (disabled) {
          $(itemForm).append(`<input type="hidden" value="1" name="${name}" />`);
        } else {
          $(itemForm).find(`input[type="hidden"][name="${name}"]`).remove();
        }
      });

      // within each colour line item
      card.querySelectorAll('.order_items .row:not(.unavailable)').forEach(colour => {
        colour.querySelectorAll('input, select, textarea').forEach(input => {
          input.disabled = disabled;
        });

        // remove colour buttons
        colour.querySelectorAll('.remove_order_item').forEach(button => {
          button.disabled = disabled;
        });
      });
    });
  }

  if (shadeCardOnlyCheckbox && cardContainer) {
    shadeCardOnlyCheckbox.addEventListener('change', function handleCheckboxChange() {
      toggleCardInputs(this.checked);
    });

    toggleCardInputs(shadeCardOnlyCheckbox.checked);
  }
}

document.addEventListener('turbolinks:load', initialize);

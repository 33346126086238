import $ from 'jquery';
import setupSelects from './helpers/setup_selects';

// select2 does not get on with turbolinks caching. The selects will
// freeze up if turbolinks tries to restore them from the cache, so
// remove them before the cache is written
$(document).on('turbolinks:before-cache', () => {
  document.querySelectorAll('select.select2').forEach(obj => {
    $(obj).select2('destroy');
  });
});

$(document).on('turbolinks:load', () => {
  const data = $('body').data();

  $(document).trigger(`${data.controller}:loaded`);
  $(document).trigger(`${data.controller}#${data.action}:loaded`);

  setupSelects();
});

// Whether an item can be edited can change depending on its state.
// However browsers keep a 'bfcache' in addition to their normal cache
// for faster history navigation that doesn't take this into account.
// So force edit pages to be reloaded if they've been loaded from this
// cache.
window.onpageshow = event => {
  if (event.persisted && (window.location.pathname.endsWith('edit') || window.location.pathname.endsWith('new'))) {
    $('main').hide(); // no matching show because the reload on the next line replaces this page
    window.location.reload();
  }
};

import $ from 'jquery';

function toggleCustomColourInputAndFlag() {
  $('.colour-select').each(function colorSelectEachCallback() {
    const formGroup = $(this).closest('.form-group');
    const customColourInput = formGroup.find('.other-colour-input');
    const isCustomColourFlag = formGroup.find('.is-custom-colour-flag');
    const selectValue = $(this).val();

    if (selectValue === 'other' || isCustomColourFlag.val() === 'true') {
      $(this).val('other').trigger('change');
      customColourInput.show();
    } else if (selectValue === 'N/A') {
      isCustomColourFlag.val('true');
      customColourInput.hide().val('N/A');
    } else {
      customColourInput.hide().val('');
      isCustomColourFlag.val('false');
    }
  });
}

function initializeColourSelect() {
  $('.colour-select').on('change', function colorSelectOnChange() {
    const formGroup = $(this).closest('.form-group');
    const customColourInput = formGroup.find('.other-colour-input');
    const isCustomColourFlag = formGroup.find('.is-custom-colour-flag');
    const selectValue = $(this).val();

    if (selectValue === 'other') {
      customColourInput.show();
      isCustomColourFlag.val('true');
    } else if (selectValue === 'N/A') {
      isCustomColourFlag.val('true');
      customColourInput.hide().val('N/A');
    } else {
      customColourInput.hide().val('');
      isCustomColourFlag.val('false');
    }
  });
}

function fetchAndDisplayCarriers(patternId) {
  $('.carrier-field').hide().find('select, input').removeAttr('required');

  $.ajax({
    url: `/gansey/patterns/${patternId}/carriers`,
    method: 'GET',
    dataType: 'json',
    success(data) {
      data.forEach(carrier => {
        const { id, line_number } = carrier;

        // disabling camelcase lint here because it makes no sense to change how the data is fed in
        // eslint-disable-next-line camelcase
        const carrierField = $(`.carrier-field[data-carrier-number="${line_number}"]`).show();

        carrierField.find('select').attr('required', 'required');
        carrierField.find('.select2').select2();
        carrierField.find('input[type="hidden"][name*="gansey_carrier_id"]').val(id);
      });
    },
  });
}

function prepareFormSubmission() {
  $('.carrier-field').each(function hideUnrequired() {
    if ($(this).is(':hidden')) {
      $(this).find('select, input').not('.carrier-id').prop('disabled', true);
    } else {
      $(this).find('select, input').prop('disabled', false);
    }
  });
}

$(document).on('turbolinks:load', () => {
  $('.carrier-field').hide();

  const patternId = $('#gansey_colourway_gansey_pattern_id').val();
  if (patternId) {
    fetchAndDisplayCarriers(patternId);
  }

  $('#gansey_colourway_gansey_pattern_id').on('change', function turbolinksLoadCallback() {
    const newPatternId = $(this).val();
    fetchAndDisplayCarriers(newPatternId);
  });

  initializeColourSelect();
  toggleCustomColourInputAndFlag();

  $('#gansey-colourway-form').off('submit').on('submit', () => {
    prepareFormSubmission();
  });
});

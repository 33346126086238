/* ===========================================================================
© Mesomorphic Ltd
============================================================================= */
import $ from 'jquery';

const generateUniqueId = () => new Date().getTime();

const select2Classes = [
  '.gansey_pattern_select:visible',
  '.gansey_colourway_select:visible',
  '.gansey_garment_select:visible',
];

function initializeSelect2(selectElement) {
  if (selectElement.data('select2')) {
    selectElement.select2('destroy');
  }

  selectElement.select2({
    theme: 'bootstrap',
    placeholder() {
      return $(this).data('placeholder') || 'Select an option';
    },
    allowClear: true,
  });
}

function initializeSelect2ForNewItem(item) {
  select2Classes.forEach(selector => {
    item.find(selector).each(function initializeSelect2Element() {
      initializeSelect2($(this));
    });
  });
}

function initializeAllSelect2() {
  select2Classes.forEach(selector => {
    $(selector).each(function initializeSelect2Element() {
      initializeSelect2($(this));
    });
  });
}

const createSizeItem = lineItemForm => {
  const newId = generateUniqueId();
  let sizeItemForm = $('#size_fields_template:first').html();

  sizeItemForm = sizeItemForm.replace(/line_item_attributes/g, newId);

  // Find the highest current index within the parent line_item and generate the next index
  const sizeFields = lineItemForm.find('.data:visible');
  const sizeIndices = sizeFields.map((_, sizeField) => {
    const dataIndex = $(sizeField).attr('data-index');
    return dataIndex ? parseInt(dataIndex, 10) : 0;
  });

  const nextIndex = sizeIndices.length ? Math.max(...sizeIndices) + 1 : 0;

  const sampleFieldName = lineItemForm.find('input[name^="gansey_order[line_items_attributes]"]').attr('name');
  const match = sampleFieldName.match(/gansey_order\[line_items_attributes\]\[(\d+)\]\[.*\]/);
  const lineItemIndex = match ? match[1] : null;

  sizeItemForm = sizeItemForm.replace(/line_items_attributes\]\[\d+\]/g, `line_items_attributes][${lineItemIndex}]`);
  sizeItemForm = sizeItemForm.replace(/line_item_sizes_attributes\]\[\d+\]/g, `line_item_sizes_attributes][${nextIndex}]`);

  const newSizeItem = $(sizeItemForm);

  // Ensure new size item is not hidden and values are cleared
  newSizeItem.css('display', '').find('input[type="number"], select').each(function displayNewSizes() {
    $(this).val('');
  });

  // Explicitly set _destroy to false for new size items
  newSizeItem.find('input[name$="[_destroy]"]').val('false');

  // Set the data-index attribute for the new size item
  newSizeItem.attr('data-index', nextIndex);

  lineItemForm.find('.line_item_sizes').append(newSizeItem);
  initializeSelect2ForNewItem(newSizeItem);

  return newSizeItem;
};

// When creating a new size item, ensure the template is updated to include data-index attribute
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('#size_fields_template').forEach(template => {
    const sizeFields = template.querySelectorAll('.row.size-fields');
    sizeFields.forEach((sizeField, index) => {
      sizeField.setAttribute('data-index', index);
    });
  });
});

const createLineItem = () => {
  const newId = generateUniqueId();
  let lineItemForm = $('#line_item_fields_template').html();

  lineItemForm = lineItemForm.replace(/new_line_items/g, newId);

  const newLineItem = $(lineItemForm);
  newLineItem.data('line-item-id', newId);

  $('#new_line_item').append(newLineItem);

  createSizeItem(newLineItem);

  initializeSelect2ForNewItem(newLineItem);

  return newLineItem;
};

const updateColourwaySelect = (select, defaultValue = null) => {
  const patternId = select.val();
  const colourwaySelect = select.closest('.panel-body').find('.gansey_colourway_select');
  const lineItemColourway = select.closest('.panel-body').data('line-item-colourway');

  colourwaySelect.empty().append('<option value="">Select Colourway</option>');

  if (patternId) {
    $.getJSON(`/gansey_patterns/${patternId}/gansey_colourways`, data => {
      data.sort((a, b) => a.number - b.number).forEach(value => {
        colourwaySelect.append($('<option>').val(value.id).text(`${value.number}`));
      });

      if (lineItemColourway) {
        colourwaySelect.val(lineItemColourway);
      } else if (defaultValue) {
        colourwaySelect.val(defaultValue);
      }

      // Refresh select2 to display the updated options
      colourwaySelect.trigger('change');
    });
  }

  if (!colourwaySelect.val()) {
    colourwaySelect.prop('selectedIndex', 1);
  }
};

const displayValidationMessage = (field, message) => {
  if (!$(field).next('.error-message').length) {
    const errorMessage = $('<div class="error-message" style="color: red; font-size: 0.9em; margin-top: 5px;"></div>').text(message);
    $(field).after(errorMessage);
  }
  $(field).addClass('is-invalid').css('border', '1px solid red');
};

const removeValidationMessage = field => {
  $(field).removeClass('is-invalid').css('border', '');
  $(field).next('.error-message').remove();
};

$(document).on('turbolinks:load', () => {
  initializeAllSelect2();

  $('.gansey_pattern_select').each((index, selectNode) => {
    updateColourwaySelect($(selectNode));
    $(selectNode).trigger('change');
  });

  $(document).on('click', '.btn.btn-success', event => {
    const form = $(event.currentTarget).closest('form');

    const hiddenFields = form.find('input[disabled], select[disabled], textarea[disabled], [type="hidden"]');
    hiddenFields.prop('disabled', false).removeAttr('hidden');

    let allValid = true;

    form.find('[required]').each((index, fieldNode) => {
      const field = $(fieldNode)[0];
      if (!field.value.trim() && field.offsetParent !== null) {
        allValid = false;
        let friendlyName = '';
        if (field.id.includes('gansey_pattern')) {
          friendlyName = 'Pattern';
        } else if (field.id.includes('gansey_colourway')) {
          friendlyName = 'Colourway';
        } else if (field.id.includes('gansey_garment')) {
          friendlyName = 'Garment';
        } else if (field.id.includes('gansey_size')) {
          friendlyName = 'Size';
        } else if (field.id.includes('quantity')) {
          friendlyName = 'Quantity';
        } else {
          friendlyName = field.name;
        }
        displayValidationMessage(field, `${friendlyName} is required.`);
        if (!field.disabled) {
          field.focus();
        }
      } else {
        removeValidationMessage(field);
      }
    });

    form.find('.line_item_size_quantity').each((index, quantityNode) => {
      const quantityField = $(quantityNode);
      if (quantityField.is(':visible')) {
        const minQuantity = parseInt(quantityField.attr('min'), 10);

        if (parseInt(quantityField.val(), 10) < minQuantity) {
          allValid = false;
          displayValidationMessage(quantityField[0], `Quantity must be at least ${minQuantity}`);
          quantityField[0].focus();
        } else {
          removeValidationMessage(quantityField[0]);
        }
      }
    });

    // Force form submission if all visible required fields are filled
    if (allValid) {
      form.find(':submit').attr('disabled', 'disabled');
      form[0].submit();
    } else {
      hiddenFields.prop('disabled', true).attr('hidden', 'hidden');
    }
  });
});

$(document).on('change', '[data-target="additionTypeSelect"], .gansey_addition_type_select, .gansey_pattern_select', event => {
  if (event.currentTarget.classList.contains('gansey_pattern_select')) {
    updateColourwaySelect($(event.currentTarget));
  }
});

$(document).on('click', '#add_line_item', event => {
  event.preventDefault();
  const newLineItem = createLineItem();
  updateColourwaySelect(newLineItem.find('.gansey_pattern_select'));
});

$(document).on('click', '#add_size', event => {
  event.preventDefault();
  const lineItemForm = $(event.currentTarget).closest('.line-item');
  createSizeItem(lineItemForm);
});

$(document).on('click', '.remove-line-item-btn', event => {
  event.preventDefault();
  const totalLineItems = $('.panel-body.line-item:visible').length;
  const lineItemForm = $(event.currentTarget).closest('.panel-body.line-item');
  if (totalLineItems > 1) {
    lineItemForm.find('input[name*="[_destroy]"]').val('1');
    lineItemForm.hide();
  } else {
    alert('At least one line item is required.'); // eslint-disable-line no-alert
  }
});

$(document).on('click', '.remove_fields', event => {
  event.preventDefault();
  const sizeItemForm = $(event.currentTarget).closest('.row.size-fields');
  const lineItemForm = $(event.currentTarget).closest('.panel-body.line-item');
  const totalVisibleSizeItems = lineItemForm.find('.row.size-fields:visible').length;

  if (totalVisibleSizeItems > 1) {
    sizeItemForm.find('input[name*="[_destroy]"]').val('true');
    sizeItemForm.hide();
  } else {
    alert('Each line item must have at least one size.'); // eslint-disable-line no-alert
  }
});

$(document).on('submit', 'form', event => {
  const form = $(event.currentTarget);
  form.find('.line-item').each((lineItemIndex, lineItem) => {
    $(lineItem).find('.row.size-fields:visible').each((sizeItemIndex, sizeItem) => {
      const inputs = $(sizeItem).find('input, select, textarea');
      inputs.each((_, input) => {
        const $input = $(input);
        const name = $input.attr('name');
        if (name) {
          const updatedName = name.replace(
            /\[line_items_attributes\]\[\d+\]\[line_item_sizes_attributes\]\[\d+\]/,
            `line_items_attributes][${$(lineItem).index()}][line_item_sizes_attributes][${sizeItemIndex}]`,
          );
          $input.attr('name', updatedName);
        }
      });
    });
  });
});

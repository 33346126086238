import $ from 'jquery';
import 'select2';

/*
  Sets all select elements with a "select2" CSS class to use Select2
  functionality. It only sets up elements that haven't been set up
  previously.

  The tags: obj.hasAttribute('freesearch') property is to allow free
  text input into a given select. When the select does not have a
  'freesearch' property, the select will be limited to selecting
  only the available options.

  By adding a class name "autosubmit" to a select2 control it will
  automatically submit the parent form when the control change event
  fires.
*/
export default function setupSelects() {
  document.querySelectorAll('select.select2').forEach(obj => {
    if (!obj.classList.contains('select2-hidden-accessible')) {
      // expose css classes ending with _type on the dropdown soe we can style it
      const selectClasses = Array.from(obj.classList).filter(cssClass => cssClass.endsWith('_type'));
      $(obj).select2({
        theme: 'bootstrap',
        tags: obj.hasAttribute('freesearch'),
        dropdownCssClass: selectClasses.join(' '),
      });
    }
  });
  $(document).on('change', '.select2.autosubmit', event => {
    event.target.form.submit();
  });
}

import $ from 'jquery';

$(document).on('click', '.modify_quantity', function modifyQuantity(e) {
  e.preventDefault();
  const button = $(this);

  $.ajax(button.attr('href')).done(data => {
    button.siblings('.pcb_num_field').first().val(data.quantity);
    $('#totalId').text(data.total);
  });
});

/* ===========================================================================
© Mesomorphic Ltd
============================================================================= */
document.addEventListener('turbolinks:load', () => {
  const fileInput = document.getElementById('file-input');
  const submitButton = document.getElementById('csv-submit');

  if (fileInput && submitButton) {
    fileInput.addEventListener('change', () => {
      const file = fileInput.files[0];
      submitButton.disabled = !(file && file.name.endsWith('.csv'));
    });
  }
});
